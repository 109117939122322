<template>
  <Item :item="item" class="content" :parent="parent">
    <Content
      v-if="tagline"
      :item="tagline"
      :parent="item"
      class="relative mb-2 font-bold uppercase"
    />

    <is-admin>
      <client-only>
        <Editor
          v-if="isAdminMode"
          :auto-focus="autoFocus"
          :styleable="item.styleable"
          :item-name="item.name"
          :headline-weight="weight"
          :content="htmlContent"
          class="content__body"
          @update="onEditorUpdate"
        />
      </client-only>
    </is-admin>

    <is-guest>
      <div class="content__body" v-html="htmlContent"></div>
    </is-guest>
  </Item>
</template>

<script>
import { dispatch, get } from 'vuex-pathify'
import itemMixin from '@/mixins/item'

export default {
  name: 'ContentItem',

  components: {
    Item: () => import('@/components/Item'),
    Content: () => import('@/components/Items/Content'),
    Editor: () => import('@/components/Editor'),
  },

  mixins: [itemMixin],

  props: {
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isAdminMode: get('isAdminMode'),

    tagline() {
      if (!this.item.items) {
        return
      }
      const tagline = this.item.items.filter(item => item.name === 'Tagline')
      if (tagline.length) {
        return tagline[0]
      }

      return null
    },

    weight() {
      return this.item.weight
    },

    htmlContent() {
      return this.item.content?.html ?? ''
    },
  },

  methods: {
    onEditorUpdate(value) {
      dispatch('page/update', {
        mutation: 'UPDATE_ITEM_CONTENT',
        item: this.item,
        value,
      })
    },
  },
}
</script>

<style lang="postcss">
[data-name='Tagline'] {
  & .content__body {
    font-size: 70%;
    color: var(--headlineColor);
    opacity: 0.65;
  }
}

.content {
  min-width: 1rem;
  color: var(--textColor);

  & > .content__body {
    p {
      @apply my-2 leading-relaxed;

      + p {
        @apply mt-5 leading-relaxed;
      }

      a {
        text-decoration: underline;

        &:hover {
          opacity: 0.85;
        }
      }
    }

    img + br {
      display: none;
    }

    :first-child {
      @apply mt-0;
    }

    :last-child:not(h2) {
      @apply mb-0;
    }

    h1 {
      @apply text-4xl;
      line-height: 1.1;
      color: var(--headlineColor);
    }

    h2 {
      @apply text-4xl;
      line-height: 1.2;
      color: var(--headlineColor);
    }

    h3 {
      @apply mt-6 mb-2 text-xl leading-tight;
      color: var(--headlineColor);
    }

    ul {
      list-style: disc;
      @apply ml-6 my-4;

      li {
        &::marker {
          font-size: 125%;
        }
      }

      ul {
        @apply my-0;

        li {
          &::marker {
            content: '-  ';
          }
        }
      }
    }

    ol {
      list-style: decimal;
      @apply ml-5 my-4;
    }

    li {
      &::marker {
        color: var(--contrastColor);
      }

      p {
        @apply mb-0;
      }
    }

    a {
      color: var(--contrastColor);
    }

    p u {
      text-decoration: none;
      background-image: linear-gradient(
        to bottom,
        transparent 65%,
        var(--lowContrastColor) 65%
      );
    }

    h1,
    h2,
    h3 {
      u {
        text-decoration: none;
        color: var(--contrastColor);
      }
    }
  }
}
</style>
